import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./layouts";

// auth
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ResetPassword from "./pages/auth/reset-password";

// //pages
import Dashboard from "./pages/dashboard";

// //apps
import { AppTicket as ApplicationTracker } from "./pages/app-ticket";
import AppContact from "./pages/app-contact";

// //EXAMPLES
import CorporateAdd from "./pages/corporate-add";
import CorporatePages from "./pages/corporate-pages";
import EcommerceAdd from "./pages/ecommerce-add";
import ExamplesDelete from "./pages/examples-delete";

// //form
import FormBasic from "./pages/form-basic";
import FormEditor from "./pages/form-editor";
import FormFileUpload from "./pages/form-file-upload";
import FormMasks from "./pages/form-masks";
import FormPicker from "./pages/form-picker";
import FormValidations from "./pages/form-validations";

// ui element
import UiBootstrapElements from "./pages/ui-bootstrap-elements";
import UiAlertify from "./pages/ui-alertify";
import UiButtons from "./pages/ui-buttons";
import UiCardMaster from "./pages/ui-card-master";
import UiCards from "./pages/ui-cards";
import UiCarousel from "./pages/ui-carousel";
import UiDraggableCards from "./pages/ui-draggable-cards";
import UiGrid from "./pages/ui-grid";
import UiIcons from "./pages/ui-icons";
import UiJqueryElements from "./pages/ui-jquery-elements";
import UiModals from "./pages/ui-modals";
import UiNestable from "./pages/ui-nestable";
import UiNotification from "./pages/ui-notification";
import UiRangeSlider from "./pages/ui-range-slider";
import UiSweetalerts from "./pages/ui-sweetalerts";
import UiTabs from "./pages/ui-tabs";
import UiTipPop from "./pages/ui-tip-pop";
import UiTreeview from "./pages/ui-treeview";
import UiTypography from "./pages/ui-typography";
import Page404 from "./pages/page-error/page-404";

import * as serviceWorker from "./serviceWorker";
import PrivateRoute from "./routes/privateRoute";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import InitializeGoogleAnalytics from "./utils/analytics/analytics";

if (process.NODE_ENV == "production") {
  InitializeGoogleAnalytics();
}

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path="/auth"
            render={() => <Redirect to="/auth/login" />}
          />
          <Route path={`/auth/login`} component={Login} />
          <Route path={`/auth/register`} component={Register} />
          <PrivateRoute
            path={`/auth/forget-password`}
            component={ResetPassword}
          />

          <Layout name="backend">
            <PrivateRoute
              exact
              path="/"
              render={() => <Redirect to="/app-ticket" />}
            />
            <PrivateRoute path={`/app-contact`} component={AppContact} />

            {/* WORK HERE */}
            <PrivateRoute path={`/app-ticket`} component={ApplicationTracker} />

            <PrivateRoute path={`/corporate-add`} component={CorporateAdd} />
            <PrivateRoute
              path={`/corporate-pages`}
              component={CorporatePages}
            />

            <PrivateRoute path={`/ecommerce-add`} component={EcommerceAdd} />
            <PrivateRoute
              path={`/examples-delete`}
              component={ExamplesDelete}
            />
            <PrivateRoute path={`/form-basic`} component={FormBasic} />
            <PrivateRoute path={`/form-editor`} component={FormEditor} />
            <PrivateRoute
              path={`/form-file-upload`}
              component={FormFileUpload}
            />
            <PrivateRoute path={`/form-masks`} component={FormMasks} />
            <PrivateRoute path={`/form-picker`} component={FormPicker} />
            <PrivateRoute
              path={`/form-validations`}
              component={FormValidations}
            />

            {/* <PrivateRoute path={`/page-faqs`} component={PageFaqs} /> */}
            {/* <PrivateRoute path={`/table-data`} component={DataTables} /> */}

            {/* form and ui element */}
            <PrivateRoute
              path={`/ui-bootstrap-elements`}
              component={UiBootstrapElements}
            />
            <PrivateRoute path={`/ui-alertify`} component={UiAlertify} />
            <PrivateRoute path={`/ui-buttons`} component={UiButtons} />
            <PrivateRoute path={`/ui-card-master`} component={UiCardMaster} />
            <PrivateRoute path={`/ui-cards`} component={UiCards} />
            <PrivateRoute path={`/ui-carousel`} component={UiCarousel} />
            <PrivateRoute
              path={`/ui-draggable-cards`}
              component={UiDraggableCards}
            />
            {/* <PrivateRoute path={`/ui-icons`} component={UiIcons} /> */}
            <PrivateRoute
              path={`/ui-jquery-elements`}
              component={UiJqueryElements}
            />
            {/* <PrivateRoute path={`/ui-modals`} component={UiModals} /> */}
            <PrivateRoute path={`/ui-nestable`} component={UiNestable} />
            <PrivateRoute
              path={`/ui-notification`}
              component={UiNotification}
            />
            <PrivateRoute path={`/ui-range-slider`} component={UiRangeSlider} />
            <PrivateRoute path={`/ui-sweetalerts`} component={UiSweetalerts} />
            <PrivateRoute path={`/ui-tabs`} component={UiTabs} />
          </Layout>
          <PrivateRoute path="*" component={Page404} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
