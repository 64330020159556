import React, { useState } from "react";
import { Link } from "react-router-dom";
import TopbarUserMessage from "./message";
import TopbarUserNotification from "./notification";
import TopbarUserProfile from "./profile";
import TopbarRightSideBarBtn from "./right-sidebar-btn";
import RightSidebar from "../right-sidebar";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

const Topbar = () => {
  const [toggleClass, setToggleClass] = useState(false);
  const [showRightSideBar, setShowRightSideBar] = useState(false);

  const handleToggleClass = (event) => {
    event.preventDefault();

    const rootNodeChildren = document.getElementById("root").children;
    for (let i = 0; i < rootNodeChildren.length; i++)
      if (rootNodeChildren[i].classList.contains("nav-collapsed"))
        rootNodeChildren[i].classList.remove("nav-collapsed");
      else rootNodeChildren[i].classList.add("nav-collapsed");
  };

  const showRightSideBarHandler = () => {
    setShowRightSideBar((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      {/* ============================================================== */}
      {/* 						Topbar Start 						   */}
      {/* ============================================================== */}
      <div className="top-bar primary-top-bar">
        <Container fluid>
          <Row>
            <Col>
              <Link className="admin-logo" to="/">
                <Image
                  alt="profile"
                  className="margin-b-10"
                  src="/assets/img/rehirephase-logo.png"
                  width={175}
                />
              </Link>
              <div
                className="left-nav-toggle"
                onClick={(e) => handleToggleClass(e)}
              >
                <Button as="a" variant="link" className="nav-collapse p-0">
                  <i className="fa fa-bars" />
                </Button>
              </div>
              <div className="left-nav-collapsed">
                <Button
                  as="a"
                  variant="link"
                  className="nav-collapsed p-0"
                  onClick={(e) => handleToggleClass(e)}
                >
                  <i className="fa fa-bars" />
                </Button>
              </div>
            </Col>
            <Col>
              <ul className="list-inline top-right-nav">
                <TopbarUserProfile />
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ============================================================== */}
      {/*                        Topbar End                              */}
      {/* ============================================================== */}

      {/* ============================================================== */}
      {/*                   RightSidebar start                           */}
      {/* ============================================================== */}

      <RightSidebar show={showRightSideBar} />

      {/* ============================================================== */}
      {/*                    RightSidebar End                            */}
      {/* ============================================================== */}
    </React.Fragment>
  );
};

export default Topbar;
