import React from "react";
import { Col, Row } from "react-bootstrap";
import { useData } from "../../context/DataContext";

export const DashboardOverview = () => {
  const { counts } = useData();

  return (
    <Row>
      <Col>
        <div className="widget bg-primary padding-15">
          <Row className="row-table">
            <Col xs="8" className="padding-15 text-center">
              <h4 className="mv-0">{counts && counts.totalCount}</h4>
              <div className="margin-b-0">Applied</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <div className="widget bg-warning padding-15">
          <Row className="row-table">
            <Col xs="8" className="padding-15 text-center">
              <h4 className="mv-0">{counts && counts.pendingCount}</h4>
              <div className="margin-b-0">Pending</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <div className="widget bg-success padding-15">
          <Row className="row-table">
            <Col xs="8" className="padding-15 text-center">
              <h4 className="mv-0">{counts && counts.interviewedCount}</h4>
              <div className="margin-b-0">Interview Scheduled</div>
            </Col>
          </Row>
        </div>
      </Col>

      <Col>
        <div className="widget bg-danger padding-15">
          <Row className="row-table">
            <Col xs="8" className="padding-15 text-center">
              <h4 className="mv-0">{counts && counts.rejectedCount}</h4>
              <div className="margin-b-0">Rejected</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <div className="widget bg-info padding-15">
          <Row className="row-table">
            <Col xs="8" className="padding-15 text-center">
              <h4 className="mv-0">{counts && counts.offerExtendedCount}</h4>
              <div className="margin-b-0">Offer Extended</div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
