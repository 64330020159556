import React, { useState } from "react";
import MenuJson from "../../data/menu.json";
import BeNavGroup from "./nav/nav-group";
import { Nav, Card, Image } from "react-bootstrap";
import { useData } from "../../context/DataContext";

export const MainSidebar = () => {
  const [menu, setMenu] = useState({ menu: MenuJson });
  const { first_name, last_name, profile_photo_url } = useData();

  return (
    <>
      {/* ============================================================== */}
      {/* 						Navigation Start 						*/}
      {/* ============================================================== */}
      <div className="main-sidebar-nav default-navigation">
        <div className="nano has-scrollbar">
          <div className="nano-content sidebar-nav ">
            <Card.Body className="border-bottom text-center nav-profile">
              <div className="notify setpos">
                <span className="heartbit" />
                <span className="point" />
              </div>
              <Image
                alt="profile"
                className="margin-b-10"
                src={profile_photo_url}
                width={80}
              />
              <p className="lead margin-b-0 toggle-none">
                {first_name} {last_name}
              </p>
              {/* <i className="text-muted mv-0 toggle-none">Software Engineer</i> */}
            </Card.Body>
            <Nav as="ul" className="metisMenu flex-column" id="menu">
              {menu.menu.map((groupItem, key) => {
                return (
                  <React.Fragment>
                    <li className="nav-heading" key={key}>
                      <span>{groupItem.groupname.toUpperCase()}</span>
                    </li>
                    <BeNavGroup menu={groupItem.children} />
                  </React.Fragment>
                );
              })}
            </Nav>
          </div>
        </div>
      </div>
      {/* ============================================================== */}
      {/* 						Navigation End	 						*/}
      {/* ============================================================== */}
    </>
  );
};

export default MainSidebar;
