import React, { useState, useEffect, useReducer } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useData } from "../../context/DataContext";
import { reducer } from "../../reducers/edit-job-listing-modal.reducer";
import { deepEqual } from "../../utils/object/deep-equal.object";

export const EditJobListingModal = ({ initialValues }) => {
  const { updateData } = useData();
  const [state, dispatch] = useReducer(reducer, initialValues);

  const [disabled, setDisabled] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const areAllPropertiesFilled = () => {
    return (
      !!state.applicationSourceUrl && !!state.companyName && !!state.jobTitle
    );
  };

  useEffect(() => {
    console.log(state.applicationSourceUrl);
    setSaveButtonDisabled(deepEqual(state, initialValues));
  }, [state]);

  const handleInputChange = (field, value) => {
    dispatch({ type: "updateField", field, value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let token = localStorage.getItem("token");

    const changedValues = {
      jobTitleId: initialValues.jobTitleId,
      companyId: initialValues.companyId,
      userJobListingsId: initialValues.userJobListingsId,
    };
    for (const key in state) {
      if (state[key] !== initialValues[key]) {
        changedValues[key] = state[key];
      }
    }

    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_JOBLISTINGS_PATH}`;

    // Send update request to the server
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
      body: JSON.stringify(changedValues),
    });

    if (response.ok) {
      // Job updated successfully, update the data
      updateData();
      handleCloseModal();
    } else {
      console.error("Failed to update job listing");
    }
  };

  return (
    <React.Fragment>
      <Button variant="success" onClick={handleShowModal} size="sm">
        <i className="fa fa-pencil" />
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Job Listing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col sm="8">
                <Form.Group>
                  <Form.Label>Application Source Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the url of the job application"
                    value={state.applicationSourceUrl}
                    onChange={(event) => {
                      event.target.value
                        ? setDisabled(false)
                        : setDisabled(true);

                      handleInputChange(
                        "applicationSourceUrl",
                        event.target.value
                      );
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Job Title"
                    value={state.jobTitle}
                    onChange={(event) => {
                      handleInputChange("jobTitle", event.target.value);
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company"
                    value={state.companyName}
                    onChange={(event) => {
                      handleInputChange("companyName", event.target.value);
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="7">
                <Form.Group>
                  <Form.Label>Work Arrangement</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={state.workArrangement}
                    onChange={(event) => {
                      handleInputChange("workArrangement", event.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option>On-Site</option>
                    <option>Remote</option>
                    <option>Hybrid</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Progress Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={state.progressStatus}
                    onChange={(event) => {
                      handleInputChange("progressStatus", event.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option>Pending</option>
                    <option>Interview-Scheduled</option>
                    <option>Rejected</option>
                    <option>Offer-Extended</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Minimum Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Minimum Salary"
                    value={state.minimumSalary}
                    onChange={(event) => {
                      handleInputChange("minimumSalary", event.target.value);
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Maximum Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Maximum Salary"
                    value={state.maximumSalary}
                    onChange={(event) => {
                      handleInputChange("maximumSalary", event.target.value);
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="8">
                <Form.Group>
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the url of the job description"
                    value={state.jobDescription}
                    onChange={(event) => {
                      handleInputChange("jobDescription", event.target.value);
                    }}
                    disabled={disabled}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Employment Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={state.employmentType}
                    onChange={(event) => {
                      handleInputChange("employmentType", event.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option>Full-Time</option>
                    <option>Part-Time</option>
                    <option>Contract</option>
                    <option>Internship</option>
                    <option>Temporary</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Priority Level</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={state.priorityLevel}
                    onChange={(event) => {
                      handleInputChange("priorityLevel", event.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={saveButtonDisabled}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
