import React from "react";
import "./index.scss";
import { Row, Col, Card } from "react-bootstrap";
import { CreateJobModal } from "../ui-modals";
import { DashboardOverview } from "./dashboard-overview";
import { JobListingsTable } from "./job-listings-table";
import { CreateJobListingModal } from "../../components/modals/create-job-listing-modal";

export const AppTicket = () => {
  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <CreateJobListingModal />
              </div>
              Career Hub
              <p className="text-muted">
                Stay organized and informed about your professional journey as
                it showcases a comprehensive overview of all your submitted job
                applications.
              </p>
            </Card.Header>
            <Card.Body>
              <DashboardOverview />
              <JobListingsTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
