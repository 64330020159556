import React from 'react';
import "./index.scss";
//import { Link } from 'react-router-dom';


class ExamplesDelete extends React.Component {
    render() {
        return (
            <React.Fragment>
            
            </React.Fragment>
        );
    }
}

export default ExamplesDelete;
