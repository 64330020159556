import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Form, Image } from "react-bootstrap";
import * as Yup from "yup";

const Register = () => {
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const formSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Enter a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formHandler = async (event) => {
    event.preventDefault();

    try {
      // Validate the form using Yup
      await formSchema.validate(formData, { abortEarly: false });

      // Define the API endpoint based on the environment
      const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/authentication/register`;

      // If validation is successful, proceed with the registration
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Handle successful registration, e.g., redirect to a success page
        console.log("Registration successful!");
        history.push("/auth/login");
      } else {
        // Handle registration error
        console.error("Registration failed.");
        setHasError(true);
      }
    } catch (error) {
      // Yup validation failed, update the errors state
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
      setHasError(true);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // If the input is a checkbox, update the state differently
    const updatedValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: updatedValue,
    }));
  };

  return (
    <React.Fragment>
      {hasError && <h1>Something went wrong.</h1>}
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <div className="col-4">
                <div className="misc-header text-center">
                  <Image
                    alt="profile"
                    className="margin-b-10"
                    src="/assets/img/rehirephase-logo.png"
                    width={175}
                  />
                </div>
                <div className="misc-box">
                  <p className="text-center">Sign up to get instant access.</p>
                  <Form onSubmit={formHandler} action={`/`}>
                    <Form.Group>
                      <label htmlFor="exampleUser1">First Name</label>
                      <div className="group-icon">
                        <input
                          id="exampleUser1"
                          type="text"
                          placeholder="Enter First Name"
                          className={`form-control ${
                            validationErrors.first_name ? "is-invalid" : ""
                          }`}
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {validationErrors.first_name && (
                          <div className="invalid-feedback">
                            {validationErrors.first_name}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleUser2">Last Name</label>
                      <div className="group-icon">
                        <input
                          id="exampleUser2"
                          type="text"
                          placeholder="Enter Last Name"
                          className={`form-control ${
                            validationErrors.last_name ? "is-invalid" : ""
                          }`}
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        {validationErrors.last_name && (
                          <div className="invalid-feedback">
                            {validationErrors.last_name}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleUser3">Email</label>
                      <div className="group-icon">
                        <input
                          id="exampleUser3"
                          type="email"
                          placeholder="Enter Email"
                          className={`form-control ${
                            validationErrors.email ? "is-invalid" : ""
                          }`}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <span className="icon-envelope text-muted icon-input" />
                        {validationErrors.email && (
                          <div className="invalid-feedback">
                            {validationErrors.email}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type="password"
                          placeholder="Password"
                          className={`form-control ${
                            validationErrors.password ? "is-invalid" : ""
                          }`}
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <span className="icon-lock text-muted icon-input" />
                        {validationErrors.password && (
                          <div className="invalid-feedback">
                            {validationErrors.password}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    {/* <Form.Group>
                      <label htmlFor="exampleInputPassword2">
                        Confirm Password
                      </label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword2"
                          type="password"
                          placeholder="Confirm Password"
                          className={`form-control ${
                            validationErrors.confirmPassword ? "is-invalid" : ""
                          }`}
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />
                        <span className="icon-lock text-muted icon-input" />
                        {validationErrors.confirmPassword && (
                          <div className="invalid-feedback">
                            {validationErrors.confirmPassword}
                          </div>
                        )}
                      </div>
                    </Form.Group> */}
                    <div className="clearfix">
                      <div className="float-left">
                        <div className="checkbox checkbox-primary margin-r-5">
                          <input
                            id="checkbox2"
                            type="checkbox"
                            defaultChecked
                            name="agreeTerms"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-primary btn-rounded box-shadow mt-10"
                    >
                      Register Now
                    </button>
                    <hr />
                    <p className="text-center">Have an account?</p>
                    <Link
                      to={"/auth/login"}
                      className="btn btn-block btn-success btn-rounded box-shadow"
                    >
                      Login
                    </Link>
                  </Form>
                </div>
                <div className="text-center">
                  <p>Copyright © 2023 RehirePhase</p>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
