import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import "./style.scss";
import { useAuthentication } from "../../context/AuthenticationContext";
import { useData } from "../../context/DataContext";

const TopbarUserProfile = () => {
  const { logout } = useAuthentication();
  const { first_name, last_name, profile_photo_url } = useData();
  const history = useHistory();

  const handleLogout = () => {
    // Clear the authentication token from local storage
    logout();

    // Redirect to the login page or any desired page after logout
    history.push("/auth/login");
  };
  return (
    <React.Fragment>
      <Dropdown as="li" className="avtar-dropdown">
        <Dropdown.Toggle>
          <Image
            src={profile_photo_url}
            className="mr-1 height-width-45"
            roundedCircle
            width={30}
          />
          {first_name} {last_name}
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className={`top-dropdown`}>
          <Dropdown.Divider />
          <Dropdown.Item as="li" onClick={handleLogout}>
            <Link to={`/auth/login`}>
              <i className="icon-logout" />
              Logout
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default TopbarUserProfile;
