import React, { useState } from "react";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useAuthentication } from "../../../context/AuthenticationContext";

const Login = () => {
  const history = useHistory();
  const { login } = useAuthentication();
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formHandler = async (event) => {
    event.preventDefault();

    try {
      // Validate the form using Yup
      await validationSchema.validate(formData, { abortEarly: false });

      // Define the API endpoint based on the environment
      const apiUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_LOGIN_PATH}`;

      // Send login request to the server
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Parse the JSON response to get the token
        const { token } = await response.json();

        // Store the token in the local storage or wherever you prefer
        login(token);

        // Redirect to a protected route or any desired page
        history.push("/app-ticket");
      } else {
        // Handle login error
        console.error("Login failed.");
        setHasError(true);
      }
    } catch (error) {
      // Handle Yup validation errors or other unexpected errors
      if (error.name === "ValidationError") {
        // Yup validation errors
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setValidationErrors(errors);
      } else {
        // Other unexpected errors
        console.error("An error occurred during login:", error);
        setHasError(true);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      {hasError && <h1>Something went wrong.</h1>}
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" md="5" lg="4" className="col-4">
                <div to="#javascript" className="misc-header text-center">
                  <Image
                    alt="profile"
                    className="margin-b-10"
                    src="/assets/img/rehirephase-logo.png"
                    width={175}
                  />
                </div>
                <div className="misc-box">
                  <Form onSubmit={formHandler}>
                    <Form.Group>
                      <label htmlFor="exampleuser1">Email</label>
                      <div className="group-icon">
                        <input
                          id="exampleuser1"
                          type="email"
                          placeholder="Email"
                          className={`form-control ${
                            validationErrors.email ? "is-invalid" : ""
                          }`}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <span className="icon-envelope text-muted icon-input" />
                        {validationErrors.email && (
                          <div className="invalid-feedback">
                            {validationErrors.email}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type="password"
                          placeholder="Password"
                          className={`form-control ${
                            validationErrors.password ? "is-invalid" : ""
                          }`}
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <span className="icon-lock text-muted icon-input" />
                        {validationErrors.password && (
                          <div className="invalid-feedback">
                            {validationErrors.password}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <div className="clearfix">
                      <div className="float-left">
                        <div className="checkbox checkbox-primary margin-r-5">
                          <input
                            id="checkbox2"
                            type="checkbox"
                            defaultChecked
                          />
                          <label htmlFor="checkbox2"> Remember Me </label>
                        </div>
                      </div>
                      <div className="float-right">
                        <input
                          type="submit"
                          className="btn btn-block btn-primary btn-rounded box-shadow"
                          value="Login"
                        />
                      </div>
                    </div>
                    <hr />
                    <p className="text-center">Need to Signup?</p>
                    <Link
                      to={`/auth/register`}
                      className="btn btn-block btn-success btn-rounded box-shadow"
                    >
                      Register Now
                    </Link>
                  </Form>
                </div>
                <div className="text-center misc-footer">
                  <p>Copyright © 2023 RehirePhase</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
