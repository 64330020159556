import React from "react";

import { Helmet } from "react-helmet";
import Topbar from "../../components/topbar/topbar";
import MainSidebar from "../../components/main-sidebar";
import { Alert } from "react-bootstrap";
import { DataProvider } from "../../context/DataContext";
// import Customizer from '../../customizer';

export default function BackendLayout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>RehirePhase</title>
        <style></style>
      </Helmet>
      <DataProvider>
        <Topbar />
        <MainSidebar />
        {/* <Alert variant="primary">
        You have an interview today with <strong>Airbnb</strong> for{" "}
        <strong>Senior Software Engineer</strong>
      </Alert> */}
        {/* <PageHeader /> */}
        <div className="main-content">
          {/* <Alert variant="primary">
            You have an technical interview today with <strong>Airbnb</strong>{" "}
            for <strong>Senior Software Engineer</strong> at 11pm.
          </Alert> */}
          {children}
          {/* <Footer /> */}
        </div>
      </DataProvider>
      {/* <Customizer />  */}
    </React.Fragment>
  );
}
