// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  const [AuthenticationState, setAuthenticationState] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  useEffect(() => {
    // Check for existing token on mount
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 > Date.now()) {
        // Token is still valid
        setAuthenticationState({
          isAuthenticated: true,
          user: decodedToken,
          loading: false,
        });
      } else {
        // Token has expired
        logout();
      }
    } else {
      setAuthenticationState({
        isAuthenticated: false,
        user: null,
        loading: false,
      });
    }
  }, []);

  const login = (token) => {
    // Save token to local storage
    localStorage.setItem("token", token);

    // Decode token to get user data
    const decodedToken = jwtDecode(token);

    setAuthenticationState({
      isAuthenticated: true,
      user: decodedToken,
      loading: false,
    });
  };

  const logout = () => {
    // Remove token from local storage
    localStorage.removeItem("token");

    setAuthenticationState({
      isAuthenticated: false,
      user: null,
      loading: false,
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{ ...AuthenticationState, login, logout }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
