import React, { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [dataState, setDataState] = useState([]);

  const fetchData = async () => {
    let token = localStorage.getItem("token");

    // Define the API endpoint based on the environment
    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_JOBLISTINGS_PATH}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (response.ok) {
        const jsonData = await response.json();
        setDataState(jsonData);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data initially

  const updateData = async () => {
    // Call the fetchData function to re-fetch the data after a new job is created
    await fetchData();
  };

  return (
    <DataContext.Provider value={{ ...dataState, updateData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
