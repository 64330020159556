export const initialState = {
  applicationSourceUrl: "",
  jobTitle: "",
  companyName: "",
  workArrangement: "On Site",
  priorityLevel: "High",
  employmentType: "Full-Time",
  progressStatus: "Pending",
  jobDescription: "",
  minimumSalary: 0,
  maximumSalary: 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "updateField":
      return { ...state, [action.field]: action.value };
    case "setAllFields":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
