import React from "react";
import { JobListingRow } from "./job-listing-row";
import { JobListingsTableHeader } from "./job-listings-table-header";

export const JobListingsTable = () => {
  return (
    <table
      id="datatable"
      className="table table-striped nowrap dataTable no-footer dtr-inline"
      width="100%"
    >
      <JobListingsTableHeader />
      <JobListingRow />
    </table>
  );
};
