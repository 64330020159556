// src/routes/PrivateRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthentication } from "../context/AuthenticationContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useAuthentication();

  if (loading) {
    console.log("loading...");
    // Render a loading spinner or any placeholder while checking authentication
    return <div>Loading...</div>;
  }

  console.log("Done loading...");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
