const isObject = (obj) => obj !== null && typeof obj === "object";

export const deepEqual = (obj1, obj2) => {
  // Check if the objects are strictly equal
  if (obj1 === obj2) {
    return true;
  }

  // Check if both values are objects
  if (isObject(obj1) && isObject(obj2)) {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate through keys and recursively compare values
    for (const key of keys1) {
      // Check if the other object contains the key and values are equal
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    // Objects are equal
    return true;
  }

  // Values are not objects or not equal
  return false;
};
