import React from "react";

export const JobListingsTableHeader = () => {
  return (
    <thead>
      <tr>
        <th>
          <strong>Job Title</strong>
        </th>
        <th>
          <strong>Company</strong>
        </th>
        <th>
          <strong>Work Arrangement</strong>
        </th>
        <th>
          <strong>Application Source</strong>
        </th>
        <th>
          <strong>Status</strong>
        </th>
        <th>
          <strong>Date</strong>
        </th>
        <th>
          <strong>Action</strong>
        </th>
      </tr>
    </thead>
  );
};
