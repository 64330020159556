import React, { useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useData } from "../../context/DataContext";

export const CreateJobListingModal = () => {
  const { updateData } = useData();
  const [applicationSourceUrl, setApplicationSourceUrl] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [workArrangement, setWorkArrangement] = useState("On-Site");
  const [priorityLevel, setPriorityLevel] = useState("High");
  const [employmentType, setEmploymentType] = useState("Full-Time");
  const [progressStatus, setProgressStatus] = useState("Pending");
  const [jobDescription, setJobDescription] = useState("");
  const [minimumSalary, setMinimumSalary] = useState(null);
  const [maximumSalary, setMaximumSalary] = useState(null);

  const [show, setShow] = React.useState(false);
  const [disabled, setDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reset = () => {
    setApplicationSourceUrl("");
    setJobTitle("");
    setCompanyName("");
    setWorkArrangement("On Site");
    setProgressStatus("Pending");
    setMinimumSalary(0);
    setMaximumSalary(0);
    setJobDescription("");
    setEmploymentType("Full-Time");
    setPriorityLevel("High");
    setDisabled(true);
    setSaveButtonDisabled(true);
  };

  const handleInputChange = () => {
    // Check if all inputs are filled
    const allInputsFilled =
      applicationSourceUrl &&
      jobTitle &&
      companyName &&
      workArrangement &&
      progressStatus;

    setSaveButtonDisabled(!allInputsFilled);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let token = localStorage.getItem("token");
    const input = {
      applicationSourceUrl,
      jobTitle,
      companyName,
      workArrangement,
      progressStatus,
      minimumSalary,
      maximumSalary,
      jobDescription,
      employmentType,
      priorityLevel,
    };

    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_JOBLISTINGS_PATH}`;

    // Send login request to the server
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
      body: JSON.stringify(input),
    });
    if (response.ok) {
      // Job created successfully, update the data
      updateData();
      reset();
      handleClose();
    } else {
      console.error("Failed to create job listing");
    }
  };

  return (
    <React.Fragment>
      <Button variant="success" onClick={handleShow} className="margin-r-5">
        Create New Job
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col sm="8">
                <Form.Group>
                  <Form.Label>Application Source Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the url of the job application"
                    value={applicationSourceUrl}
                    onChange={(event) => {
                      event.target.value
                        ? setDisabled(false)
                        : setDisabled(true);

                      setApplicationSourceUrl(event.target.value);
                      handleInputChange();
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Job Title"
                    value={jobTitle}
                    onChange={(event) => {
                      setJobTitle(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company"
                    value={companyName}
                    onChange={(event) => {
                      setCompanyName(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="7">
                <Form.Group>
                  <Form.Label>Work Arrangement</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={workArrangement}
                    onChange={(event) => {
                      setWorkArrangement(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  >
                    <option>On Site</option>
                    <option>Remote</option>
                    <option>Hybrid</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Progress Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={progressStatus}
                    onChange={(event) => {
                      setProgressStatus(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  >
                    <option>Pending</option>
                    <option>Interviewing</option>
                    <option>Rejected</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Minimum Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Minimum Salary"
                    value={minimumSalary}
                    onChange={(event) => {
                      setMinimumSalary(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Maximum Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Minimum Salary"
                    value={maximumSalary}
                    onChange={(event) => {
                      setMaximumSalary(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col sm="8">
                <Form.Group>
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the url of the job description"
                    value={jobDescription}
                    onChange={(event) => {
                      setJobDescription(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Employment Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={employmentType}
                    onChange={(event) => {
                      setEmploymentType(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  >
                    <option>Full-Time</option>
                    <option>Part-Time</option>
                    <option>Contract</option>
                    <option>Internship</option>
                    <option>Temporary</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="5">
                <Form.Group>
                  <Form.Label>Priority Level</Form.Label>
                  <Form.Control
                    as="select"
                    name="account"
                    className="m-b"
                    value={priorityLevel}
                    onChange={(event) => {
                      setPriorityLevel(event.target.value);
                      handleInputChange();
                    }}
                    disabled={disabled}
                  >
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={saveButtonDisabled}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
