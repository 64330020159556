import React from "react";
import { useData } from "../../context/DataContext";
import { Button } from "react-bootstrap";
import { EditJobListingModal } from "../../components/modals/edit-job-listing-modal";

const getStatusLabelColor = (status) => {
  switch (status) {
    case "Interviewing":
      return "success";
    case "Pending":
      return "warning";
    case "Rejected":
      return "danger";
    default:
      return "default";
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

const TruncateText = ({ text, maxLength }) => {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = text.substring(0, maxLength) + "...";
  return <span title={text}>{truncatedText}</span>;
};

export const JobListingRow = () => {
  const { JobListings } = useData();
  return (
    <tbody>
      {JobListings && JobListings.length > 0 ? (
        // Render job listings if they exist
        JobListings.map((job) => (
          <tr key={job.id}>
            <td>{job.position_title}</td>
            <td>
              {job.Company.logo_icon_url && (
                <img
                  alt="user"
                  className="media-box-object rounded-circle mr-2"
                  src={job.Company.logo_icon_url}
                  width={30}
                />
              )}
              {job.Company.name}{" "}
              {/* Assuming the Company object has a 'name' property */}
            </td>
            <td>{job.UserJobListings.work_arrangement}</td>
            <td>
              {!!job.application_source_url && (
                <a href={job.application_source_url}>
                  <TruncateText
                    text={job.application_source_url}
                    maxLength={50}
                  />
                </a>
              )}
            </td>
            <td className="text-center">
              <span
                className={`label label-${getStatusLabelColor(
                  job.UserJobListings.progress_status
                )}`}
              >
                {job.UserJobListings.progress_status}
              </span>
            </td>
            <td>{formatDate(job.createdAt)}</td>
            <td className="text-center">
              {/* <Button variant="success" size="sm">
                <i className="fa fa-pencil" />
              </Button> */}
              <EditJobListingModal
                initialValues={{
                  jobTitleId: job.id,
                  userJobListingsId: job.UserJobListings.id,
                  companyId: job.Company.id,
                  applicationSourceUrl: job.application_source_url,
                  jobTitle: job.position_title,
                  companyName: job.Company.name,
                  workArrangement: job.UserJobListings.work_arrangement,
                  priorityLevel: job.UserJobListings.priority_level,
                  employmentType: job.UserJobListings.employment_type,
                  progressStatus: job.UserJobListings.progress_status,
                  jobDescription: job.UserJobListings.description,
                  minimumSalary: job.UserJobListings.minimum_salary,
                  maximumSalary: job.UserJobListings.maximum_salary,
                }}
              />{" "}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="6" className="text-center">
            No job listings available.
          </td>
        </tr>
      )}
    </tbody>
  );
};
